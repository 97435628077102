<template>
  <div class="billing" @click="handlePageDialogClick">
    <div class="top">
      <!-- <search :userInfomation="userInfo"></search> -->
      <div v-if="userInfo" class="usr" @click="handleUserClick">
        <img :src="userInfo.userAva" />
        <span>{{ userInfo.userRealname }}{{ userInfo.userMobile }}</span>

        <span>客户编号：{{ userInfo.userNo }}</span>
      </div>
      <div class="search-user">
        <div class="phone">
          <!-- <span class="text">选择他人权益卡：</span> -->
          <el-input ref="phoneInputRef" @keyup="handlePhoneEnter($event, 0)" @mouseup="handlePhoneEnter($event, 0)" @change="handlePhonechange" v-model.trim="cardUserMobile" placeholder="选择他人权益卡(请输入手机号)" />
          <div v-if="currentUser.hasOwnProperty('userAva') && isShowUserInfo" class="user-info">
            <img :src="currentUser.userAva" alt="" />
            <div class="name">{{ currentUser.userRealname }}</div>
            <div class="phone">{{ currentUser.userMobile }}</div>
            <el-icon @click="handleClosrClick" class="close">
              <close />
            </el-icon>
          </div>
        </div>
        <div v-if="isShowSearchTips" class="search-tips">
          <template :key="useritem" v-for="useritem in searchResList">
            <div @click="handleSearchUserClick(useritem)" class="user-item">
              <img :src="useritem.userAva" alt="" />
              <div class="name">
                {{ useritem.userRealname }}({{ useritem.userMobile }})
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="billing-main-content">
        <div class="billing-left">
          <el-scrollbar class="el-scrollbar1">
            <el-tabs v-model="billingTabs" class="billing-tabs" @tab-click="handleTabsClick">
              <el-tab-pane name="1" label="服务开单">
                <div class="serve">
                  <div class="category-list">
                    <template :key="item" v-for="(item, index) in serveCategoryInfoList">
                      <div @click="handleItemClick(item, index)" :class="{ actives: currentIndex == index }" class="item">
                        {{ item.kindName }}
                      </div>
                    </template>
                  </div>

                  <div class="serve-list">
                    <template :key="item" v-for="item in serveList">
                      <div @click="handleServeClick(item)" class="item">
                        <div>{{ item.serveName }}</div>
                        <div>{{ item.serveLength }}分钟</div>
                        <div class="price">¥{{ item.price }}</div>
                      </div>
                    </template>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane name="2" label="团购兑换">
                <template v-if="couponDialogVisible&&!paydialogVisible">
                  <couponDialog :couponDialogVisible="couponDialogVisible" :userInfo="orderServeInfoData" @couponDialogClose="couponDialogClose" @refreshInfo="refreshInfo">
                  </couponDialog>
                </template>
                <!-- <coupon-dialog></coupon-dialog> -->
              </el-tab-pane>
              <el-tab-pane name="3" label="卡包">
                <card></card>
              </el-tab-pane>
              <el-tab-pane name="4" label="优惠券">
                <coupon v-if="billingTabs==4"></coupon>
              </el-tab-pane>
            </el-tabs>
          </el-scrollbar>
        </div>
        <div class="billing-right">
          <el-scrollbar class="el-scrollbar2">
            <div class="user-serve">
              <div class="buy-serve">
                <template :key="ele" v-for="(ele, index) in orderServeInfoData.itemList">
                  <div class="serve-item">
                    <div class="tops">
                      <div class="name">{{ ele.name }}</div>
                      <div class="buy-num">
                        x 1
                      </div>
                      <div class="price">¥{{ (ele.currentPrice!=undefined?ele.currentPrice:ele.price).toFixed(2) }}</div>
                    </div>
                    <div class="original_price" :style="(ele.cardNoList&&ele.cardNoList.length)||ele.deductionNo||couponNo?'':'opacity: 0'">
                      <span style="text-decoration: line-through; margin-right: 15px;">原价：¥{{ (ele.price).toFixed(2) }}</span>
                      <span>-¥{{ (ele.discountPrice||0).toFixed(2) }}</span>
                    </div>
                    <div class="bottom">
                      <div class="teacher">理疗师:{{ ele.empName }}
                        <div class="ispoint"> {{  orderServeInfoData.isPoint ? '回客' : ''  }}</div>
                        <div class="ispoint"> {{  orderServeInfoData.isNewCustomer ? '新客' : ''  }}</div>
                      </div>
                      <div class="quanyi">
                        <el-select v-model="ele.deductionNo" clearable @change="handleQuanyi($event,index)" class="m-2" placeholder="权益" size="large" style="width: 150px;margin-right: 10px;" :disabled="ele.cardNoList&&ele.cardNoList.length">
                          <el-option :disabled="item.count<=0 " @click="handleServeDeductionClick(item, ele, index)" v-for="item in ele.serveDeductionList" :key="item.cardNo" :label="item.name " :value="item.cardNo" />
                        </el-select>
                        <el-select v-model="ele.cardNoList" clearable multiple @clear="changeTag($event,index)" class="m-2" placeholder="请选择充值卡" size="large" style="width: 180px;" :disabled="ele.deductionNo&&ele.deductionNo!=''">
                          <el-option :disabled="item.balance<=0||ele.cardNoList&&ele.cardNoList.length>=1&&item.type==6" @click="handleStoredCardClick(item, ele, index,indexs)" v-for="(item,indexs) in ele.storedCardList" :key="item.cardNo" :label="item.name " :value="item.cardNo" />
                        </el-select>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </el-scrollbar>
          <div class="order-collection">
            <div class="bottom">
              <div class="comment-form">
                <p style="margin-bottom: 10px;">订单备注</p>
                <el-input show-word-limit maxlength="100" v-model="remark" :rows="2" type="textarea" placeholder="输入订单备注" />
              </div>
              <div class="comment-form" v-if="orderServeInfoData.couponList&&orderServeInfoData.couponList.length">
                <p style="margin-bottom: 10px;">优惠</p>
                <span class="el-dropdown-link" v-if="couponNoName">
                    {{couponNoName}}
                    <el-icon style="margin-left: 6px;font-size: 16px;" @click="deleteCoupon" color="red"><CircleClose /></el-icon>
                  </span>
                <el-dropdown trigger="click" v-else>
                  <span class="el-dropdown-link">
                    {{orderServeInfoData.couponList.length+'张优惠券可用'}}
                    <el-icon class="el-icon--right">
                      <arrow-down />
                    </el-icon>
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item v-for="(item,index) in orderServeInfoData.couponList" :key="index" @click="changeCoupon(item)">{{ item.name }}</el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
              <div class="pay-wrap">
                <div class="pay-tips">
                  <span>待支付:</span>
                  <span class="price">¥{{ totalPrice }}</span>
                </div>
                <div class="pay-opt">
                  <div class="pay-btn">
                    <template v-if="!isBuyServe">
                      <el-button v-if="isRecharge == true" :disabled="isOptRechargeType == true" @click="handlePayClick" type="primary">收款</el-button>
                      <el-button v-else :disabled="optCardNum == 0" @click="handlePayClick" type="primary">收款</el-button>
                    </template>
                    <template v-else>
                      <el-button @click="handlePayClick" type="primary">收款</el-button>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <template v-if="paydialogVisible">
    <payDialog :paydialogVisible="paydialogVisible" :isKaika="true" :reserveNo="currentOrder.code" :payPrice="currentOrder.unpaidPrice" :isBack="true" :orderKind="currentOrder.orderKind" :curentOrderInfo="currentOrder" :oid="currentOrder.id" @changeDialogVisible="payChangeDialogVisible" :dialogVisible="paydialogVisible" @paySuccess="paySuccess">
    </payDialog>
  </template>
  <!-- <template v-if="couponDialogVisible">
    <couponDialog :couponDialogVisible="couponDialogVisible" :userInfo="orderServeInfoData" @paySuccess="paySuccess" @couponDialogClose="couponDialogClose">
    </couponDialog>
  </template> -->
</template>

<script lang="js">
import { defineComponent, reactive, ref, onUnmounted, computed, watch, provide, nextTick } from 'vue'
import payDialog from '../base-ui/pay-dialog/pay-dialog.vue'
import emitter from '@/utils/eventbus'
import { useRoute,useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import { getUserInfo,calculatePrice } from '@/service/main/order'
import { searchKeywordCustomer,getTeacherRankServeList,getAppPayInfo,getAppPayUserInfo,saveDeductionMode,pullCashier} from '@/service/main/reserve'
import { ElMessage } from 'element-plus'
import couponDialog from './cpns/coupon-dialog'
import Card from './cpns/card/card.vue'
import Coupon from './cpns/coupon/coupon.vue'


export default defineComponent({
  props: {

  },
  components: {
    payDialog,
    couponDialog,
    Card,
    Coupon
  },
  setup() {
    const billingTabs = ref('1')
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const phone = computed(() => route.query.phone)
    const clientInfo = computed(() => store.state.reserve.clientInfo)
    const paydialogVisible = ref(false)
    const couponDialogVisible = ref(false)
    const orderServeList = ref([])
    const orderServeInfoData = ref({})
    const serveCategoryInfoList = ref([])
    const remark = ref("")
    const serveList = ref([])
    const orderId = computed(() => route.query.orderNo)
    const userId = computed(() => route.query.userId)
    const serveId = computed(() => route.query.id)
    const payChangeDialogVisible = (flag) => {
      paydialogVisible.value = flag
    }

    watch(paydialogVisible, (n) => {
      if (n == false) {
        setTimeout(() => {
          clearTimeout(window.timer)
        }, 5000);
      }
      // console.log(n);

    }, {
      deep: true
    })
    const paySuccess = () => {
      paydialogVisible.value = false
      router.push('/main/reserve/check')
    }


    const currentOrder =  ref('')

    const userInfo = ref('')
    const initPage = async () => {
      const res = await getUserInfo(phone.value)
      userInfo.value = res.data
      getServeList()
      getServeOrderList()
      // store.dispatch('order/getServeCategoryListAction', { companyId: COMPANY_ID })
      // store.dispatch('order/getOrderDetailAction', [COMPANY_ID, orderId.value, cardCurrentUser.value.userId])
    }
    initPage()

    const handleUserClick = (item) => {
      router.push({
        path: '/main/customer/detail',
        query: {
          userId: userInfo.value.userId,
          phone: userInfo.value.userMobile,
        }
      })
    }
    //获取服务数据
    const getServeList = async() => {
      let data = await getTeacherRankServeList({userId:userId.value,shopId:route.query.shopId})
      let list = data.data
      let newList = {kindName:'所有服务',kindId:0,infos:[]}

      list.forEach((ele, index) => {
        let paralist = ele.infos
        newList.infos.push(...paralist)
      })
      serveCategoryInfoList.value=[newList,...data.data]
      handleItemClick(newList,0)
    }
    //获取预约的支付信息
    const getServeOrderList = async(userId) => {
      let data;
      if (userId) {
      data = await getAppPayUserInfo(serveId.value,userId)
      }else{
      data = await getAppPayInfo(serveId.value)
      }
      orderServeList.value=data.data.itemList
      orderServeInfoData.value=data.data;
      totalPrice.value=data.data.price
    }
    const refreshInfo = () =>{
      countPrice()
    }

    let cardUserMobile = ref('')
    const currentType = ref(0)
    const isShowSearchTips = ref(false)
    const searchResList = ref([])


    // 手机号搜索卡项事件
    const handlePhoneEnter = async (e, type) => {

      let keyword = e.target.value
      currentType.value = type
      const res = await searchKeywordCustomer({
        keyword
      })
      searchResList.value = res.data.list
      if (searchResList.value.length > 0) {
        isShowSearchTips.value = true
      }
    }
    // 改变手机号搜索事件
    const handlePhonechange = value => {
      // cardUserId = ''
      if (value.length >= 11) {
        store.dispatch('reserve/getClientInfoAction', value)
      }
    }

    const isShowUserInfo = ref(false)
    watch(clientInfo, (n) => {
      if (n.hasOwnProperty('userId')) {
        isShowUserInfo.value = true
        currentUser.userId = n.userId
        currentUser.userRealname = n.userRealname
        currentUser.userMobile = n.userMobile
        currentUser.userAva = n.userAva
      }
    })

    const currentUser = reactive({
      userId: '',
      userRealname: '',
      userMobile: '',
      userAva: ''
    })
    const cardCurrentUser = computed(() => currentUser)
    // provide('cardCurrentUser', cardCurrentUser)

    const phoneInputRef = ref()
    // 用户信息搜索卡项事件
    const handleSearchUserClick = (item) => {
      isShowUserInfo.value = true
      currentUser.userId = item.userId
      currentUser.userRealname = item.userRealname
      currentUser.userMobile = item.userMobile
      currentUser.userAva = item.userAva
      cardUserMobile.value = item.userMobile
      // console.log(currentUser)
      getServeOrderList(currentUser.userId)
      phoneInputRef.value.focus()
      setTimeout(() => {
        phoneInputRef.value.blur()
      }, 200);
    }
    // 用户信息关闭按钮
    const handleClosrClick = () => {
      currentUser.userId = ''
      isShowUserInfo.value = false
      cardUserMobile.value = ''
      getServeOrderList()
    }

    const handlePageDialogClick = () => {
      isShowSearchTips.value = false
    }
    const quanyiValue = reactive({
    })
    const currentIndex = ref(0)
    const handleItemClick = (item, index) => {
      currentIndex.value = index
      serveList.value=item.infos
    }

    const handleServeClick = (item) => {
      emitter.emit('changeOrderBillingServeItemOpt', item)
    }
    const orderData = ref({})
    const handlePayClick = async () =>{
      // if (!orderData.value.orderId) {
        let list = orderServeInfoData.value.itemList;
        let itemDeductionList = list.map(item=>{
          return{
            cardNoList:item.cardNoList||"",
            itemId:item.itemId,
            deductionType:item.deductionType||"",
            deductionNo:item.deductionNo||""
          }
        })

        let obj = {
          id:serveId.value,
          itemDeductionList,
          remark:remark.value,
          couponNo:couponNo.value?couponNo.value:undefined
        }
        let orderDataInfo = await saveDeductionMode(obj)
        if (orderDataInfo.code!=0) {
          ElMessage({
            message: orderDataInfo.msg,
            type: 'warning',
          })
          // router.push('/main/reserve/check')
          return
        }
        orderData.value=orderDataInfo.data;
      // }
      if (orderData.value.price==0) {
        // console.log(orderData.value.price);
        ElMessage({
          message: '支付成功！',
          type: 'success',
        })
        router.push('/main/reserve/check')
      }else{
        // console.log(orderData.value.price);
        let payData = await pullCashier(orderData.value.orderId)
        currentOrder.value = payData.data
        paydialogVisible.value=true
        setTimeout(() => {
          emitter.emit('changeOrderPayDialogSearchPayState')
        }, 2000);
        return
      }
    }
    const handleServeDeductionClick = (deduction, item, index) => {
      let data = orderServeInfoData.value.itemList[index]
      if (deduction.consume!=undefined&&(deduction.consume/deduction.length)>=deduction.count) {
        return
      }
      data.deductionNo=deduction.cardNo;
      data.deductionType=deduction.type;
      countPrice()
    }
    const handleStoredCardClick = (cardItem, item, index,indexs) => {
      console.log(cardItem, item, index,indexs);
      let data = orderServeInfoData.value.itemList[index]
      if (!(data.cardNoList&&data.cardNoList.length)) {
        // data.discountPrice=undefined;
        // data.preferential=undefined;
        data.hasZhekou=false
        if (couponNo.value) {
          countPrice()
        } 
        return
      }
      let cardNoIndex = data.cardNoList.findIndex(item => {
          return item =item.cardNo
      })
      if (couponNo.value) {
        if(cardItem.type==5){
          countPrice()
        }else if (cardItem.type==6) {
            data.cardNoList.splice(cardNoIndex,1)
             ElMessage({
              message: '已选择优惠券，无法使用折扣卡',
              type: 'error',
            })
            return
        }
      }else{
        if (data.cardNoList.length>1) {
          let isHasZhekouList = []
          let isHasZhekou = false
          data.cardNoList.map(item => {
            data.storedCardList.map(it =>{
              if (item==it.cardNo) {
                isHasZhekouList.push(it)
              }
            })
          })
          isHasZhekou=isHasZhekouList.some(item=>{return item.type==6})
          if (isHasZhekou) {
            data.cardNoList.splice(cardNoIndex,1)
            ElMessage({
              message: '折扣卡无法多选',
              type: 'error',
            })
            return
          }
          countPrice()
        }else{
          //5储值卡
          if(cardItem.type==5){
            countPrice()
          }else if (cardItem.type==6) {
            countPrice()
            data.hasZhekou=true
          }
        }
      }
    }

    const changeTag =(e,index)=>{
      console.log(e);
      let data = orderServeInfoData.value.itemList[index]
      data.cardNoList=[]
      countPrice()
    }
    const handleQuanyi = (e,index) =>{
      let data = orderServeInfoData.value.itemList[index]
      if (e=='') {
        data.deductionNo=undefined;
        data.deductionType=undefined;
        countPrice()
      }
    }
    const totalPrice = ref(0)

    const couponNo = ref('')
    const couponNoName = ref('')
    const changeCoupon = (item) => {
      let data = orderServeInfoData.value.itemList
      data.map(item=>{
        const newArr = item.storedCardList.filter(items => item.cardNoList.includes(items.cardNo))
        if (newArr.length) {
          let isHasZhekou=newArr.some(items=> items.type==6)
          item.hasZhekou=isHasZhekou
        }
      })
      let isHasZhekou=data.some(item=> item.hasZhekou)
      if(isHasZhekou){
        ElMessage({
          message: '已选择折扣卡，无法使用优惠券',
          type: 'error',
        })
        return
      }
      couponNo.value=item.couponNo
      couponNoName.value=item.name
      countPrice()
    }

    const deleteCoupon =() =>{
      couponNo.value=''
      couponNoName.value=''
      countPrice()
    }
    
    const handleTabsClick = (e) => {
      if (billingTabs.value==2) {
        couponDialogVisible.value=true
      }else{
        couponDialogVisible.value=false
      }
    }
    const couponDialogClose = (flag) => {
      couponDialogVisible.value=flag
      if (!flag) {
        billingTabs.value='1'
      }
    }
    const countPrice = async()=>{
      let list = orderServeInfoData.value.itemList;
      let itemDeductionList = list.map(item=>{
        return{
          cardNoList:item.cardNoList||undefined,
          itemId:item.itemId,
          deductionType:item.deductionType||undefined,
          deductionNo:item.deductionNo||undefined
        }
      })
      let obj = {
        appId:serveId.value,
        itemList:itemDeductionList,
        couponNo:couponNo.value?couponNo.value:undefined
      }
      let data = await calculatePrice(obj)
      if (data.code==0) {
        orderServeInfoData.value.itemList=data.data.itemList
        totalPrice.value=data.data.price
      }else{
        ElMessage({
          message: data.msg,
          type: 'error',
        })
      }
    }

    return {
      userInfo,
      initPage,
      paydialogVisible,
      payChangeDialogVisible,
      billingTabs,
      currentOrder,
      handleUserClick,
      cardUserMobile,
      currentType,
      currentUser,
      isShowUserInfo,
      phoneInputRef,
      isShowSearchTips,
      searchResList,
      cardCurrentUser,
      handleClosrClick,
      handlePhoneEnter,
      handlePhonechange,
      handleSearchUserClick,
      handlePageDialogClick,
      orderServeList,
      orderServeInfoData,
      quanyiValue,
      serveCategoryInfoList,
      serveList,
      handleItemClick,
      currentIndex,
      handleServeClick,
      orderId,
      handlePayClick,
      getServeList,
      getServeOrderList,
      handleServeDeductionClick,
      handleStoredCardClick,
      remark,
      orderData,
      totalPrice,
      paySuccess,
      couponNo,
      couponNoName,
      changeCoupon,
      handleQuanyi,
      deleteCoupon,
      changeTag,
      handleTabsClick,
      couponDialogVisible,
      couponDialogClose,
      refreshInfo
    }

  }
})
</script>

<style scoped lang="less">
.billing {
  margin: 20px 20px 0 20px;
  padding: 20px;
  background-color: #fff;
  min-height: 100vh;
  min-width: 1080px;
  .usr {
    width: 25%;
    padding: 0 10px;
    background: #d0a571;
    height: 40px;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    span {
      margin: 0 5px;
    }
    img {
      width: 26px;
      height: 26px;
      border-radius: 50%;
    }
  }

  &:deep(.el-scrollbar2) {
    height: calc(100vh - 420px);
  }

  &:deep(.el-scrollbar1) {
    height: calc(100vh - 260px);
  }
  &:deep(.el-tag .el-tag__close){
    display: none;
  }

  .bottom {
    .billing-main-content {
      display: flex;

      & > div {
        flex: 1;
      }

      .billing-left {
        .serve {
          display: flex;

          .actives {
            background-color: #fff !important;
            color: rgb(255, 141, 26);
            font-weight: 550;
          }

          .category-list {
            flex: 1;

            .item {
              cursor: pointer;
              text-align: center;
              font-size: 14px;
              line-height: 45px;
              background-color: rgb(239, 239, 239);
              border-bottom: 1px solid rgb(255, 255, 255);
              min-width: 100px;
              cursor: pointer;
            }
          }

          .serve-list {
            margin-left: 20px;
            flex: 5;
            display: flex;
            // justify-content: center;
            flex-wrap: wrap;
            align-content: flex-start;

            .item {
              border-radius: 6px;
              font-size: 13px;
              display: flex;
              flex-direction: column;
              padding: 10px;
              justify-content: space-evenly;
              width: 220px;
              // max-width: 300px;
              min-height: 60px;
              background-color: rgb(239, 239, 239);
              margin-right: 10px;
              cursor: pointer;
              margin-bottom: 10px;
              & > div {
                margin-bottom: 4px;
              }
              .price {
                color: rgb(255, 141, 26);
              }
            }
          }
        }
      }

      .billing-right {
        margin-left: 20px;
        .user-serve {
          padding-bottom: 100px;

          .buy-serve {
            border-radius: 5px;
            font-size: 14px;
            padding: 20px;
            // height: 194px;
            margin-bottom: 10px;

            .serve-item {
              position: relative;
              padding: 20px 20px 0 20px;
              margin-bottom: 20px;
              color: rgba(80, 80, 80, 1);
              background-color: rgba(239, 239, 239, 1);
              min-height: 134px;

              .bottom {
                // width: 92%;
                // position: absolute;
                // bottom: 20px;
                margin-top: 10px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                border-top: 1px solid #ccc;
                padding-top: 12px;
                .quanyi {
                  display: flex;
                }

                .teacher {
                  flex-shrink: 0;
                  display: flex;
                  .ispoint {
                    color: #ff6600;
                  }
                }
              }

              .tops {
                display: flex;
                justify-content: space-between;
                align-items: center;
                // padding-bottom: 34px;
                margin-bottom: 5px;
                .buy-num {
                  &:deep(.el-input-number) {
                    max-width: 120px;
                  }
                }

                .name,
                .price {
                  color: rgba(80, 80, 80, 1);
                  // color: var(--borderColor);
                  font-size: 16px;
                  font-weight: bold;
                  // padding-right: 42px;
                }
              }
              .original_price {
                display: flex;
                justify-content: flex-end;
              }
            }
          }
        }
        .order-collection {
          position: fixed;
          width: calc((100% - 212px) / 2);
          padding-right: 10px;
          bottom: 0px;
          background-color: rgba(255, 255, 255, 0.8);
          padding: 20px 10px;

          &:deep(.el-collapse) {
            width: 100%;

            .el-collapse-item {
              border-top: none !important;
              border-bottom: none !important;
            }
          }
          .comment-form {
            font-size: 13px;
            margin-bottom: 35px;
            .el-dropdown-link {
              cursor: pointer;
              // color: #409EFF;
              display: flex;
              align-items: center;
            }
            .el-icon-arrow-down {
              font-size: 12px;
            }
          }
          .pay-wrap {
            display: flex;
            justify-content: space-between;
            margin-top: 80px;

            .pay-tips {
              .price {
                font-size: 24px;
                font-weight: 550;
              }
            }

            .pay-opt {
              display: flex;
              justify-content: flex-end;
              flex: 1;
              padding-left: 10px;

              .pay-btn {
                margin-left: 20px;
              }

              .serve-page {
                &:deep(.el-select) {
                  margin: 0 10px;
                  max-width: 160px;
                }
              }
            }

            .price {
              color: rgba(212, 48, 48, 1);
              font-size: 24px;
            }
          }
        }
      }
    }
  }

  .top {
    margin-bottom: 20px;
    display: flex;
  }
  .search-user {
    width: 80%;
    margin: 0 10px;
  }
  .search-user {
    position: relative;

    .search-tips2 {
      margin-top: 44px !important;
    }

    .search-tips {
      position: absolute;
      width: 300px;
      z-index: 100;
      background-color: #fff;
      // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
      margin-top: 6px;

      .user-item {
        display: flex;
        align-items: center;
        line-height: 46px;
        cursor: pointer;
        padding: 0 10px;
        border-bottom: 1px solid rgba(204, 204, 204, 1);

        img {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
    }

    .phone {
      display: flex;
      .text {
        line-height: 40px;
      }

      &:deep(.el-input) {
        min-width: 300px !important;

        .el-input__inner {
          min-width: 300px !important;
        }
      }

      .user-info {
        width: 30%;
        margin-left: 10px;
        display: flex;
        align-items: center;
        background-color: rgb(250, 232, 228);
        padding-left: 10px;
        position: relative;

        .close {
          position: absolute;
          right: 4px;
          top: 4px;
          font-size: 12px;
          cursor: pointer;
        }

        img {
          width: 30px;
          height: 30px;
          margin-right: 6px;
        }

        .name {
          margin-right: 6px;
        }
      }

      &:deep(.el-input) {
        max-width: 200px;
      }
    }
  }
}
</style>
