<template>
  <div class="card-consume-dialog">
    <pageDialog
      width="480px"
      title="消费详情"
      @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible"
    >
      <el-scrollbar height="260px">
        <template v-if="currentCardKind == 5 || currentCardKind == 6">
          <div class="titles">
            <div class="name">类型</div>
            <div>购买金额</div>
            <div>剩余金额</div>
          </div>

          <div class="item item2">
            <div class="key">本金</div>
            <div class="value">{{ (cardYue.bjbuy / 100).toFixed(2) }}</div>
            <div class="value">{{ (cardYue.cardCash / 100).toFixed(2) }}</div>
          </div>
          <div class="item item2">
            <div class="key">赠金</div>
            <div class="value">{{ (cardYue.zjbuy / 100).toFixed(2) }}</div>
            <div class="value">{{ (cardYue.cardCashProm / 100).toFixed(2) }}</div>
          </div>
        </template>
        <template v-else>
          <div class="serve-project">
            <div class="titles">
              <div class="name">服务名称</div>
              <div>购买次数</div>
              <div>剩余次数</div>
              <div>平均价</div>
            </div>
            <div class="title">绑定服务详情:</div>

            <template v-for="item in serveList">
              <div class="item">
                <div class="name">{{ item.serveName }}</div>
                <div>
                  <template v-if="item.payQty != null">
                    {{ item.payQty }}次
                  </template>
                  <template v-else> -</template>
                </div>
                <div class="qty">{{ item.qty }}次</div>
                <div>
                  <template v-if="item.averagePrice != null">
                    {{ (item.averagePrice / 100).toFixed(2) }}
                  </template>
                  <template v-else> - </template>
                </div>
              </div>
            </template>
            <div class="title">赠送服务详情:</div>
            <template v-for="item in handselServeList">
              <div class="item">
                <div class="name">{{ item.serveName }}</div>
                <div>
                  <template v-if="item.qty != null">
                    {{ item.qty }}
                  </template>
                  <template v-else> -</template>
                </div>
                <div class="qty">{{ item.qty }}次</div>
                <div>
                  <template v-if="item.averagePrice != null">
                    {{ (item.averagePrice / 100).toFixed(2) }}
                  </template>
                  <template v-else> - </template>
                </div>
              </div>
            </template>
          </div>
        </template>
        <div style="display: flex; align-items: baseline;">
          <div class="title">到期时间:</div>
          <el-date-picker
            v-model="expireTime"
            type="datetime"
            size="mini"
            :disabled-date="disableDate" 
            :disabled-hours="disabledHour"
            :disabled-minutes="disabledMinute" 
            :disabled-seconds="disabledSecond"
            :disabled="isDisabled"
            @change="handleChange"
            >
          </el-date-picker>
          <el-button type="primary" size="mini" @click="isDisabled = false" style="margin-left: 5px;">更改</el-button>
        </div>
      </el-scrollbar>
    </pageDialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import pageDialog from '@/components/page-dialog/page-dialog.vue'
import { getCardYue, getCardServeYue, getCardBenjinServeYue, getCardBuyYue, editUserCardExpireTime } from '@/service/main/customer'
import moment from 'moment'
import { ElMessage } from 'element-plus'
export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    currentCardNo: {
      type: Number,
      default: 0
    },
    currentCardKind: {
      type: Number,
      default: 0
    },
  },
  components: {
    pageDialog
  },
  setup(props, { emit }) {

    const changeDialogVisible = flag => {
      emit('changeDialogVisible', flag)
    }

    const cardYue = reactive({
      cardCash: 0,
      cardCashProm: 0,
      bjbuy: 0,
      zjbuy: 0
    })

    const serveList = ref([])
    const handselServeList = ref([])
    const expireTime = ref('')
    const isDisabled = ref(true)

    const initPage = async () => {
      let cardType = props.currentCardKind
      let cardNo = props.currentCardNo
      if (cardType == 5 || cardType == 6) {
        const res = await getCardBuyYue(cardNo)
        let detail = res.data.carddetail
        cardYue.cardCash = detail.cardCash
        cardYue.cardCashProm = detail.cardCashProm
        cardYue.bjbuy = detail.salePrice
        cardYue.zjbuy = detail.promPrice
        expireTime.value = detail.expireTime
      } else {
        const res = await getCardBenjinServeYue(cardNo)
        // console.log(res);
        serveList.value = res.data.serves
        handselServeList.value = res.data.giftserves
        expireTime.value = res.data.expireTime
      }
    }
    initPage()
    
    const handleChange = async (val) => {
      expireTime.value = moment(val).format('YYYY-MM-DD HH:mm:ss');
      // console.log('handleChange expireTime',expireTime.value)
      // console.log(props.currentCardNo)
      const res = await editUserCardExpireTime({
        cardNo: props.currentCardNo,
        time: expireTime.value
      })
      // console.log(res)
      if (!res.data) {
        ElMessage({
          message: '修改成功!',
          type: 'success'
        })
        isDisabled.value = true
      }else {
        ElMessage({
          message: '修改失败!',
          type: 'error'
        })
      }
    }
    /* 限制天 */
    const disableDate = (time) => {
      return time.getTime() < Date.now() - 8.64e7;
    }

    /* 限制小时 */
    const disabledHour = () => {
      const arrs = []
      for (let i = 0; i < 24; i++) {
        if (new Date().getHours() <= i) continue;
        arrs.push(i)
      }
      return arrs;
    }

    /* 限制分 */
    const disabledMinute = () => {
      const arrs = []
      for (let i = 0; i < 60; i++) {
        if (new Date().getMinutes() <= i) continue;
        arrs.push(i)
      }
      return arrs;
    }

    /* 限制秒 */
    const disabledSecond = () => {
      const arrs = []
      for (let i = 0; i < 60; i++) {
        if (new Date().getSeconds() <= i) continue;
        arrs.push(i)
      }
      return arrs;
    }

    return {
      handselServeList,
      serveList,
      cardYue,
      changeDialogVisible,
      expireTime,
      handleChange,
      isDisabled,
      disableDate,
      disabledHour,
      disabledMinute,
      disabledSecond
    }

  }
})
</script>

<style scoped lang="less">
.card-consume-dialog {
  .titles {
    display: flex;
    background-color: rgb(229, 229, 229);
    line-height: 30px;
    padding: 0 10px;

    .name {
      flex: 2;
    }

    & > div {
      flex: 1;
    }
  }

  .title {
    font-size: 12px;
    font-weight: 550;
    padding: 0 10px;
    margin-top: 20px;
  }

  .item2 {
    .key {
      flex: 2 !important;
    }
  }

  .item {
    padding-left: 10px;
    display: flex;
    margin-top: 20px;
    font-size: 12px;

    .name {
      flex: 2;
    }

    & > div {
      flex: 1;
    }
  }

  .serve-project {
  }
}
</style>
