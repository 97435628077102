<template>
  <div class="dialog-form">
    <div class="main-content">
          <div class="pay-type">
              <div class="title">
                  选择验券方式
              </div>
              <div style="margin-bottom: 20px;">
                  <el-radio-group v-model="radio" @change="changeRadio">
                      <el-radio :label="item.id" v-for="item in radioList" :key="item">{{item.name}}</el-radio>
                  </el-radio-group>
              </div>
              <div class="pay-item">
                  <template :key="item" v-for="(item, index) in typeList">
                  <div @click="handlePayBookkeepingCategoryClick(item, index)" :class="{ actives: typeId == item.id }"
                      class="item">
                      {{ item.name }}
                  </div>
                  </template>
              </div>
          </div>
          <div class="QR-code">
              <div v-if="typeId==1" class="code">
                  <img src="@/assets/img/main/order/saomaqiang.png" alt="" />
              </div>
              <div class="cash" v-else>
                  <div class="pay-price">请输入兑换券号</div>
                  <div class="top-form">
                      <el-input clearable @change="handleInputChange" v-model="code" placeholder="请输入" />
                  </div>
                  <div class="bottom-btn">
                      <el-button v-if="radio!=3&&typeId==2" @click="handleCouponClick" size="large" type="primary">确认验券</el-button>
                      <el-button v-if="radio==3" @click="handleCouponDuihuanClick" size="large" type="primary">确认兑券</el-button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>
  
  <script lang="js">
  import { defineComponent, reactive, ref, watch, computed, onUnmounted,nextTick } from 'vue'
  import UploadImg from '@/components/page-upload/upload-img/upload-img.vue'
  import { COMPANY_ID } from '@/common/constant'
  import { useStore } from 'vuex'
  import { useState } from '@/hooks'
//   import { rankProps, genderList } from './config'
//   import pay from '../../pay/pay.vue'
  import emitter from '@/utils/eventbus'
  import { useRoute } from 'vue-router'
  import { exchangeCoupon,scanExchangeCoupon,exchangeUserCoupon } from '@/service/main/order'
  import { ElMessage,ElNotification } from 'element-plus'
  
  export default defineComponent({
    props: {
      couponDialogVisible: {
        type: Boolean,
        default: false
      },
      oid: {
        type: Number,
        default: -1
      },
      orderKind: {
        type: Number,
        default: 0
      },
      payPrice: {
        type: Number,
        default: 0
      },
      isBack: {
        type: Boolean,
        default: false
      },
      isKaika: {
        type: Boolean,
        default: false
      },
      curentOrderInfo: {
        type: Object,
        default() {
          return {}
        }
      },
      reserveNo: {
        type: Number,
        default: -1
      },
      userInfo:{
        type: Object,
        default() {
          return {}
        }
      }
    },
    components: {
    //   UploadImg,
    //   pay
  
    },
    emits: ['changeDialogVisible','paySuccess','couponDialogClose','refreshInfo'],
    setup(props, { emit }) {
      const store = useStore()
      const route = useRoute()
      const isShow = ref(props.couponDialogVisible)
      const userInfo = ref(props.userInfo)
      const typeList1 = reactive([{id:1,name:'扫码验券'},{id:2,name:'输入券号'}])
      const typeList2 = reactive([{id:2,name:'输入券号'}])
      const radioList = reactive([{id:1,name:'大众点评'},{id:2,name:'抖音'},{id:3,name:'兑换券'}])
      const typeList = ref([])
      const typeId = ref(1)
      const radio = ref(1)
      const code = ref('')
      const inputCache = ref('')
      const keypress = (event)=>{
        const key = event.key; // 获取按下的键名
        if (radio.value==3||typeId.value==2) {
          return
        }
        if (key === 'Enter') {
          onConfirm(inputCache.value);
          inputCache.value = '';
        } else {
          if (key!='Shift') {
            inputCache.value += key;
          }
        }
        // console.log('键盘按下: ',inputCache.value);
      }
      const initPage = async() => {
        typeList.value = typeList1
        document.addEventListener('keydown',keypress);
      }
      initPage()

      const onConfirm = async(value) =>{
        console.log(value)
        if (!value) {
            ElMessage({
                message: '请先扫描二维码！',
                type: 'error',
            })
            return
        };
        let params = {
            shopId:route.query.shopId,
            code:value,
            userId:userInfo.value.userId,
            type:radio.value
        }
        console.log("value",value,params)
        let data = await scanExchangeCoupon(params)
        if (data.code==0) {
            ElNotification.success({
              title: '验券成功！',
              message: data.data.couponName+'已验券成功',
              position: 'top-left',
              offset: 100,
              duration: 0
            })
            emit('refreshInfo')
        }else{
            ElMessage({
                message: data.msg,
                type: 'error',
            })
        }
      }
  
      const handleDialogClose = async() => {
        emit('couponDialogClose', false)
      }
  
      const changePayType = item => {
        emit('changePayType', item)
      }
      const changeRadio = () =>{
        if (radio.value!=3) {
          typeId.value = 1
          typeList.value = typeList1
        }else if ( radio.value==3 ) {
          typeId.value = 2
          typeList.value = typeList2
        }
      }
      const handlePayBookkeepingCategoryClick = (item, index) => {
        typeId.value = item.id
        code.value = ''
      }
      const handleCouponClick = async() => {
        if (!code.value) {
            ElMessage({
                message: '请先输入券号！',
                type: 'error',
            })
            return
        }
        let params = {
            shopId:route.query.shopId,
            code:code.value,
            userId:userInfo.value.userId,
            type:radio.value
        }
        let data = await exchangeCoupon(params)
        if (data.code==0) {
            ElNotification.success({
              title: '验券成功',
              message: data.data.couponName+'已验券成功',
              position: 'top-left',
              offset: 100,
              duration: 0
            })
            code.value=''
            emit('refreshInfo')
        }else{
            ElMessage({
                message: data.msg,
                type: 'error',
            })
        }
      }
      const handleCouponDuihuanClick = async() => {
        if (!code.value) {
            ElMessage({
                message: '请先输入券号！',
                type: 'error',
            })
            return
        }
        let params = {
            code:code.value,
            userId:userInfo.value.userId
        }
        let data = await exchangeUserCoupon(params)
        if (data.code==0) {
            ElNotification.success({
              title: '兑换成功！',
              message: data.data.couponName+'已兑换成功！',
              position: 'top-left',
              offset: 100,
              duration: 0
            })
            code.value=''
            emit('refreshInfo')
        }else{
            ElMessage({
                message: data.msg,
                type: 'error',
            })
        }
      }
      onUnmounted(() => {
        isShow.value = false
        document.removeEventListener("keydown", keypress);
      })
  
      return {
        changePayType,
        handleDialogClose,
        isShow,
        COMPANY_ID,
        typeList,
        radioList,
        handlePayBookkeepingCategoryClick,
        typeId,
        radio,
        code,
        handleCouponClick,
        userInfo,
        changeRadio,
        handleCouponDuihuanClick
      }
  
    }
  })
  </script>
  
  <style scoped lang="less">
  .dialog-form {
    z-index: 999;
  
    &:deep(.el-dialog) {
      min-height: 82vh;
      margin: 8vh auto;
    }
  
    .teacher {
      display: flex;
      flex-wrap: wrap;
  
      .actives {
        background-color: rgb(255, 162, 68);
        color: white;
      }
  
      .item {
        border-radius: 6px;
        cursor: pointer;
        padding: 0 10px;
        width: 140px;
        line-height: 45px;
        margin: 0 10px;
        border: 1px solid rgba(217, 217, 217, .8);
      }
  
    }
  
    &:deep(.el-scrollbar) {
      padding-right: 20px;
    }
    .main-content {
        display: flex;

        .pay-type {
            margin-right: 20px;
            .title {
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;
            }
            .pay-item {
                display: flex;
                flex-wrap: wrap;

                .actives {
                    background-color: rgb(255, 146, 36);
                    color: white;
                }

                .item {
                    width: 46%;
                    line-height: 60px;
                    margin: 0 1%;
                    border: 1px solid rgb(221, 221, 221);
                    border-radius: 6px;
                    text-align: center;
                    margin-bottom: 20px;
                    cursor: pointer;
                }
            }
        }
        .pay-type,
        .pay-receipt {
        width: 300px;
        padding: 20px;
        }

        .QR-code {
            margin-top: 20px;
            padding: 20px;
            flex: 1;
            height: 500px;
            // border-right: 1px solid #ccc;
            border-left: 1px solid #ccc;
            display: flex;
            flex-direction: column;
            align-items: center;
            .code {
                display: flex;
                flex-direction: column;
                align-items: center;

                img {
                    width: 210px;
                    height: 210px;
                }
            }
            .cash {
                width: 100%;

                .pay-price {
                    text-align: center;
                    font-size: 18px;
                    font-weight: 500;
                    margin-bottom: 30px;
                }

                .tips {
                    display: flex;
                    justify-content: center;
                    margin-top: 20px;

                    .back {
                        margin-left: 10px;
                        color: #ff6600;
                    }
                }

                .bottom-btn {
                    display: flex;
                    justify-content: center;
                    bottom: 0;
                    position: relative;
                    top: 150px;

                    &:deep(.el-button) {
                        width: 240px;
                        height: 46px;
                    }
                }

                .top-form {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .small-change {
                        margin-left: 20px;
                        color: rgb(64, 158, 255);
                        cursor: pointer;
                    }

                    &:deep(.el-input) {
                     max-width: 280px;
                    }
                }
            }
        }
    }
  
  }
  </style>
  